.logginn-page {
  form {
    max-width: 250px;
    .button-container {
      margin-top: 20px;
    } 
  }
  .logginn__img-container {
    display: none;
  }
  a {
    text-decoration: underline;
    margin-right: 10px;
  }
}

.sjekk-epost {
  max-width: 300px;
  margin-left: 20px;
}

.password-update-success {
  text-align: center;
  .login-button{
    background-color: $neutral-color;
    border: 3px solid $colour-secondary;
    padding: 4px 8px;
    border-radius: 5px;
    width: 100px;
    text-decoration: none;
    text-transform: uppercase;
    margin: 20px auto;
    display: inline-block;
    &:hover {
      border-color: darken($color: $colour-secondary, $amount: 20%);
    }
    &:hover:after {
      width: 0;
    }
  }
}



@media (min-width: 600px) {
  .logginn-page {
    form {
      margin: unset;
    }
    .logginn-container{
      display: flex;
      justify-content: space-evenly;
      width: 90%;
      max-width: 700px;
      margin: 30px auto;
    }
    .logginn__img-container {
      width: 200px;
      display: unset;
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}