.chat-modal {
  // display: none;
  position: fixed;
  z-index: 15;
  width: 60%;
  min-height: 200px;
  padding-top: 40px;
  border: 2px solid rgb(213, 213, 213);
  border-radius: 5px;
  top: 20vh;
  left: 20%;
  background-color: $colour-primary;

  .chat-history {
    height: 50vh;
    background-color: rgb(252, 252, 252);
    border: none;
    font-size: $font-size-sm;
  }

  form {
    background-color: rgb(252, 252, 252);
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    max-width: 100%;
    margin: -1px;
    border: 1px solid rgb(213, 213, 213);


    input {
      background-color: rgb(252, 252, 252);
      font-size: $font-size-sm;
      padding-left: 10px;
      width: 100%;
      margin: 0;
      border: none;
      outline: none;
    }

    button {
      border: none;
      background-color: gray;
      color: $neutral-color;
      border-radius: 20px;
      text-transform: capitalize;
      font-size: $font-size-sm;
      padding: 0 10px;
      margin-right: 5px;
      height: 30px;
      transition: all 0.3s;

      &:hover {
        filter: brightness(150%);
      }
    }


  }



  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: rgb(203, 203, 203);
      border-radius: 2px;
    }

    &::after {
      content: "";
      background-color: #000000;
      position: absolute;
      width: 20px;
      height: 2px;
      rotate: 45deg;
      translate: -50%;
      left: 50%;
    }

    &::before {
      content: "";
      background-color: black;
      position: absolute;
      width: 20px;
      height: 2px;
      rotate: -45deg;
      translate: -50%;
      left: 50%;
    }
  }
}

.cancel, .chat {
  margin-left: 50px;
}

.ratingContainer {
  background-color: #80c4ff;
  width: 50%;
  position: absolute;
  top: 20%;
  left: 20%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  display: none;
  justify-content: end;

  .ratingButton {
     margin-top: 50px;
     align-self: end;
    // float: right;

  }

  #feedbackRating {
    display: none;
  }

  #thankYouRating {
    text-align: center;
  }
}



.ratingContainer p, .ratingContainer h2  {
  // margin-left: 20px;
  align-self: center;
}

.rating  {
  display: flex;
  flex-direction: row;

  div {
    padding: 3px 15px;
    background-color: white;
    border: 1px solid;
    border-radius: 3px;
    margin: 0px 5px;
  }

  div:hover {
    background-color: $colour-primary;
  }

}




.profile__rating {
  align-self: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
  

.profile__rating h4 {
  // text-align: center;
}

.iconStar:hover {
  fill: black;
}