.messages__message {
  text-align: center;
  width: 90%;
  margin: 20px auto;
  max-width: 800px;
}

.message__summary {
  text-transform: capitalize;
  background-color: $background-chat;
  border: 2px solid $colour-secondary;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: $color-chat-hover;
  }
  &.open {
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
  }
}

.chat-window {
  width: 100%;
  form {
    margin-top: 0;
    max-width: unset;
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      margin-top: 0;
      width: 100%;
      border-radius: 0 0 0 5px;
      border: 1px solid black;
    }
    button {
      height: 30px;
      padding: 6px 24px;
      border: 1px solid black;
      border-radius: 0 0 5px 0;
      background-color: $colour-secondary;
    }
  }
  height: 0px;
  overflow: hidden;
  transition: all 0.2s linear;
}

.chat-window.show {
  height: 300px;
}

.chat-history {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid black;
  border-bottom: none;
  height: 200px;
  background-color: $background-chat;
  overflow: auto;
  scrollbar-color: $colour-secondary;
  scroll-padding-bottom: 5px;
  text-align: left;
  .me {
    text-align: right;
    .chat-item {
      background-color: #96d0ff;
    }
  }
  .chat-item {
    padding: 2px 8px;
    font-size: $font-size-sm;
    border-radius: 10px;
    background-color: $colour-primary;
    margin: 5px;
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .chat-window {
    form {
      button {
        height: 37px;
      }
    }
  }
}
