.results-container {
  width: 80%;
  margin: 20px auto 40px auto;
}

.profile-card {
  display: flex;
  flex-direction: column;
  background-color: $colour-primary;
  border: 2px solid $colour-text;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto 20px auto;
  img {
    border-radius: 10px 10px 0 0;
    width: 100%;
  }
  .profile__details {
    padding: 10px;
  }
  h3 {
    margin: 0 auto 10px auto;
  }
  h4 {
    // display: inline-block;
    // width: 110px;
    // margin: 10px auto;
    margin: 10px 0px 5px 0px;
  }
  .profile__rating {
    // position: relative;
    svg {
      position: relative;
      top: 5px;
      left: -15px;
      height: 25px;
      g path {
        fill: black;
      }
    }
  }
  .link-container {
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
    text-align: center;
    a {
      border: 2px solid black;
      background-color: #ffe600;
      &:hover {
        background-color: lighten($color: #ffe600, $amount: 20%);
      }
    }
  }
}

@media (min-width: 600px) {
  .profile-card {
    max-width: 550px;
    flex-direction: row;
    max-height: 250px;
    .profile__details {
      min-width: 300px;
      flex: 1;
    }
    .image-container {
      flex: 1;
      overflow: hidden;
      display: flex;
      justify-content: center;
      border-radius: 10px 0 0 10px;

      img {
        // border-radius: 10px 0 0 10px;
        height: 100%;
        width: unset;
      }
    }
  }
}

@media (min-width: 1024px) {
  .results-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .profile-card {
    flex-direction: column;
    max-height: unset;
    a {
      font-size: $font-size-sm;
    }
    max-width: 300px;
    width: 100%;
    .image-container {
      max-width: unset;
      overflow: visible;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
    .profile__details {
      min-width: unset;
    }
  }
}

@media (min-width: 1400px) {
  .results-container {
    grid-template-columns: 1fr 1fr;
  }
  .profile-card {
    max-width: unset;
    flex-direction: row;
    max-height: unset;
    .image-container {
      flex: 2;
      // overflow: hidden;
      height: 100%;
      img {
        border-radius: 10px 0 0 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        outline: 1px solid black;
        
        // max-height: 300px;
      }
    }
    .profile__details {
      flex: 3;
      padding-left: 30px;
    }
  }


}

// @media (min-width: 1800px) {
//   .results-container {
//     width: 90%;
//     grid-template-columns: 1fr 1fr 1fr;
//   }
// }
