

.bloggarticles {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 200px;
  gap: 40px;  

  .bloggarticle {
    display: grid;
    border-radius: 20px;
    border: 1px solid $colour-secondary;
    min-height: 500px;
    width: 45%;
    box-shadow: 4px 4px 10px rgb(162, 156, 156);

    // color: red;

    img {
      width: 500px;
      height: 200px;
      object-fit: cover;
    }
  }

  .bloggarticle h2, .bloggarticle p, .bloggarticle button {
    margin: 20px 20px;

  }

  .bloggarticle button {
    justify-self: end;
    align-self: flex-end;
    max-height: 50px;
    max-width: 200px;
  }

  .bloggarticle img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }



}

.article {
  margin: 0px 20%;
  img {


        width: 100%;
        height: 300px;
        object-fit: cover;
      

}
}

.notFound{
  display: flex;
  justify-content: center;

  img {
    width: 40%;
    align-self: center;
    margin: 20px 0px;
  }
}


.text-editor {
  display: flex;
  flex-direction: column;

  .createArticleForm {
    display: flex;
    flex-direction: column;
    // max-width: 80%;
    // padding-right: 70%;
    margin-left: 100px;
  }

  .textEditorCont {
    max-width: 80%;
    // margin-left: 100px;
    margin: 0px 0px 100px 100px;
  }
  
  .ql-container {
    min-height: 500px;

  }

  label {
    margin-top: 20px;
  }
}

@media (max-width: 700px){


  
  .text-editor {

    overflow-x: hidden;

    .createArticleForm {
      display: flex;
      flex-direction: column;
      padding-right: 0%;
      margin-left: 00px;
      margin-right: 0px;
     
    }

    .textEditorCont {
      margin: 0px;
    }
  }
}