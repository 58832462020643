#hamburger {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  padding: 0;
  background-color: transparent;
  .burger-bit {
    transition: all linear 0.2s;
    display: inline-block;
    height: 3px;
    width: 30px;
    position: absolute;
    right: 5px;
    border-radius: 1px;
    background-color: $colour-secondary;
  }
  .burger-top {
    top: 10px;
    opacity: 1;
  }
  .burger-middle1 {
    top: 19px;
  }
  .burger-middle2 {
    top: 19px;
  }
  .burger-bottom {
    top: 28px;
    opacity: 1;
  }
  @media (min-width: 800px) {
    display: none;
  }
}

.open-menu #hamburger {
  .burger-top {
    opacity: 0;
  }
  .burger-middle1 {
    transform: rotate(45deg);
  }
  .burger-middle2 {
    transform: rotate(-45deg);
  }
  .burger-bottom {
    opacity: 0;
  }
}

.open-menu ul {
  left: 0%;
}

nav {
  background-color: $colour-primary;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
  display: flex;
  ul {
    transition: all linear 0.2s;
    // border-bottom: 2px solid $colour-secondary;
    // width: 100%;
    // position: fixed;
    z-index: 10;
    // top: 40px;
    padding: 0 20px;
    margin: 0;
    // left: 100%;
    // background-color: $colour-primary;
    li {
      text-align: center;
      list-style: none;
      text-transform: uppercase;
      margin: 20px auto;
      button {
        padding: 0;
        background-color: unset;
      }
    }
    .navbar__login,
    .navbar__logout {
      background-color: $neutral-color;
      border: 3px solid $colour-secondary;
      padding: 4px 8px;
      border-radius: 5px;
      width: 80px;
      &:hover {
        border-color: darken($color: $colour-secondary, $amount: 20%);
      }
      &:hover:after {
        width: 0;
      }
    }
  }
}

.navLink-messages {
  svg {
    width: 30px;
    height: 25px;
    margin-top: 2px;
  }
  &:hover {
    svg {
      fill: lighten($color: $colour-secondary, $amount: 20%);
    }
    &:after {
      width: 0;
    }
  }
}
.navLink-profile {
  svg {
    width: 30px;
    height: 30px;
  }
  &:hover {
    circle {
      fill: lighten($color: $colour-secondary, $amount: 20%);
    }
    &:after {
      width: 0;
    }
  }
}
.navbar__logout {
  height: 22px;
}

.navLinkActive {
  &:after {
    width: 100%;
  }
}

.navbar__title {
  padding-top: 0px;
  padding-left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  img {
    height: 60px;
    width: 60px;
    margin-right: 5px;
  }
  width: 230px;
  font-size: 32px;
  font-family: $font-title;
}

@media (max-width: 499px) {
  nav {
    ul {
      // display: none;
    }
  }
}

@media (min-width: 500px) {
  nav {
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    ul {
      position: relative;
      top: unset;
      left: unset;
      width: unset;
      background-color: transparent;
      border-bottom: none;
      margin: 0;
      display: flex;
      align-items: center;
      border-bottom: none;
      li {
        margin: 0 0 0 25px;
        a {
          position: relative;
          &:after {
            transition: all 0.2s linear;
            content: "";
            position: absolute;
            display: inline-block;
            height: 2px;
            width: 0;
            bottom: 0;
            left: 0;
            background-color: black;
          }
          &:hover:after {
            width: 100%;
          }
        }
      }
    }
  }
  .navbar__title {
    padding-top: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 1024px) {
  nav {
    height: 60px;
    padding: 20px 5% 10px 5%;
    .navLink-messages svg {
      width: 35px;
      height: 30px;
      margin-top: 2px;
    }
    .navLink-profile svg {
      width: 35px;
      height: 35px;
    }
    .navbar__logout {
      height: 26px;
    }
  }
  .navbar__title {
    img {
      padding-top: 20px;
      height: 180px;
      width: 180px;
    }
    font-size: 50px;
  }



}

@media screen and (max-width:499px) {
  #mobileMeny {
    position: absolute;
    right: 0;
    top: 50px;
    display: none;
    background-color: $colour-primary;
    min-height: 150px;
  }
  
}