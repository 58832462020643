// Colours

$colour-primary: #def0ff;
$colour-secondary: #1a94ff;
$colour-text: #282b2e;
$neutral-color: #ffff;

$background-chat: $neutral-color;
$color-chat-hover: $colour-primary;

//Fonts

@font-face {
  font-family: "Alegreya Sans";
  src: url(../fonts/AlegreyaSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Cabin";
  src: url("../fonts/Cabin-VariableFont_wdth\,wght.ttf") format("truetype")
    tech(variations);
  font-weight: 400;
  font-stretch: 0% 10%;
}
@font-face {
  font-family: "Oxygen";
  src: url(../fonts/Oxygen-Regular.ttf) format("truetype");
}

$font-title: "Alegreya Sans", sans-serif;
$font-heading-link-cta: "Cabin", sans-serif;
$font-copy: "Oxygen", sans-serif;

$font-size-sm: 16px;
$font-size-md: 21px;
$font-size-lg: 28px;
$font-size-xl: 37px;
