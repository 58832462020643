.featured-container {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: $colour-secondary;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.featured-card {
  border-radius: 10px;
  position: relative;
  font-size: 0px;
  img {
    border-radius: 10px;
    width: 100%;
  }
}

.featured__details {
  position: absolute;
  font-size: $font-size-sm;
  bottom: 0;
  padding: 5px 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2) 20%,
    rgba(0, 0, 0, 0.5) 50%
  );
  border-radius: 0 0 10px 10px;
  width: 100%;
  text-align: center;
  div {
    color: $neutral-color;
  }
}

.featured-container {
  @media (min-width: 450px) {
    padding: 15px;
    grid-gap: 15px;
  }
  @media (min-width: 600px) {
    width: 80%;
  }
  @media (min-width: 800px) {
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1024px) {
    .featured__details {
      font-size: $font-size-md;
    }
  }
  @media (min-width: 1400px) {
    grid-gap: 30px;
    padding: 30px;
    width: 80%;
    max-width: 1500px;
  }
}
