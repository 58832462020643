
.nykunde-page {
  form {
    max-width: 280px;
    .button-container {
      margin-top: 40px;
    }
  }
  
}


#errorMsg {
  text-align: center;
}

@media (min-width: 600px) {
  .nykunde-page {
    form {
      max-width: 320px;
      .adresse-container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        column-gap: 30px;
        .gate {
          flex-basis: 100%;
        }
        .postnummer {
          flex: 1;
        }
        .by {
          flex: 3;
        }
        
      }
    }
  }
}

@media (min-width: 1024px) {
  .nykunde-page {
    form {
      max-width: 500px;
      .adresse-container {
        .gate {
          flex-basis: unset;
          flex: 2;
        }
        .by {
          flex-basis: 100%;
          input {
            width: 313px;
          }
        }
      }
      .navn-container, .contact-container {
        display: flex;
        align-items: stretch;
        column-gap: 30px;
        div {
          flex: 1;
        }
        .epost {
          flex: 2;
        }
      }
      .passord {
        width: auto;
      }
    }
  }


}