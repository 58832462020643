body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // max-width: 1920px;
  margin: 0 auto;
}

// ---sticky footer---

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1;
}
// ---sticky footer---

// ---text styling---

h1,
h2,
h3 {
  font-weight: 400;
}

h1,
h2,
h3,
a,
button,
label {
  font-family: $font-heading-link-cta;
  color: $colour-text;
}

button,
a {
  letter-spacing: 1px;
}

a {
  text-decoration: none;
}

button,
.cta {
  padding: 8px 24px;
  font-size: $font-size-sm;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
}

h1 {
  font-size: $font-size-lg;
  text-align: center;
  // text-transform: capitalize;
}

h2,
h3 {
  text-align: center;
  font-size: $font-size-md;
}

input {
  font-size: $font-size-sm;
  padding: 3px;
  margin-top: 5px;
}

div,
p,
input,
label,
span {
  color: $colour-text;
  font-family: $font-copy;
}

@media (min-width: 1024px) {
  h1 {
    font-size: $font-size-xl;
    margin: 50px auto;
  }
  h2,
  h3 {
    font-size: $font-size-lg;
  }
  button,
  .cta,
  a,
  label,
  input,
  div,
  span,
  textarea {
    font-size: $font-size-md;
  }
  p {
    font-size: $font-size-md;
  }
}

// ---text styling---

// ---form styling---

form {
  // width: 90%;
  // max-width: 600px;
  margin: 20px auto 0px auto;
  .form__error {
    color: rgb(200, 0, 0);
  }
  .form-item {
    min-height: 80px; // to give room for error messages
    input,
    textarea {
      width: 100%;
      display: block;
      border: 2px solid rgba(86, 100, 117, 0.719);
      border-radius: 5px;
    }
  }
  .button-container {
    text-align: center;
    button {
      background-color: $colour-secondary;
      &:disabled {
        background-color: lightgray;
        &:hover {
          background-color: lightgray;
          cursor: auto;
        }
      }
      &:hover {
        cursor: pointer;
        background-color: lighten($color: $colour-secondary, $amount: 10%);
      }
    }
  }
}

@media (min-width: 1024px) {
  form {
    .form-item {
      min-height: 90px;
    }
  }
}

// ---form styling---
