.hero {
  height: 600px;
  width: 100%;
  background-image: url("../../../images/mobile-hero-image.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  h1 {
    text-transform: none;
  }
  .link-container {
    position: absolute;
  }
}

.landing-page {
  .link-container {
    bottom: 20px;
    width: 100%;
    text-align: center;
    .landing-cta {
      background-color: $neutral-color;
      border: 3px solid $colour-secondary;
      &:hover {
        border-color: darken($color: $colour-secondary, $amount: 20%);
      }
    }
  }
}

.hero__caption {
  position: relative;
  top: 40px;
  max-width: 250px;
  margin: 0 auto;
  font-family: $font-title;
}

.landing-page h2 {
  text-align: center;
  max-width: 280px;
  margin: 40px auto 10px auto;
}

hr {
  height: 5px;
  border: none;
  background-color: $colour-secondary;
  width: 60%;
}

.oppskrift {
  .link-container {
    margin: 90px auto 120px auto;
    position: relative;
  }
  .cta {
    background-color: $colour-secondary;
    box-shadow: #fff 0px 0px 0px 2px;
    &:hover {
      background-color: lighten($color: $colour-secondary, $amount: 10%);
    }
    &::after {
      position: absolute;
      left: 50%;
      top: -30px;
      z-index: -1;
      content: "";
      width: 150px;
      height: 80px;
      background-color: $colour-primary;
      border-radius: 10px;
    }
  }
  ol {
    width: 90%;
    padding-left: 30px;
    margin: 0 auto;
    li {
      margin: 20px 0;
      font-size: $font-size-md;
    }
  }
}

@media (min-width: 450px) {
  .hero {
    height: 750px;
  }
  h2 {
    max-width: unset;
  }
}

@media (min-width: 600px) {
  .hero {
    background-image: url(../../../images/desktop-hero-image.jpg);
    background-position: 5%;
    height: 600px;
    .hero__content {
      position: absolute;
      top: 200px;
      left: 8%;
    }
    .hero__caption {
      top: unset;
    }
    .link-container {
      display: relative;
      top: 150px;
      bottom: unset;
    }
  }
  .oppskrift {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (min-width: 800px) {
  .intro {
    margin: 80px auto;
  }
  .landing-page h2 {
    max-width: unset;
    margin: 30px auto;
  }
}

@media (min-width: 1024px) {
  .hero {
    height: 700px;
    .hero__caption {
      max-width: 300px;
    }
  }
  .intro {
    h2 {
      margin: 40px auto;
    }
  }
  .oppskrift ol li {
    margin: 30px 0;
  }
}

@media (min-width: 1400px) {
  .hero {
    .hero__content {
      left: 10%;
    }
  }
}
