// .profile-page {
//   .profile__omrader {
//     display: flex;
//     flex-direction: column;
//   }
//   .clickable-detail {
//     display: flex;
//     // max-width: 720px;

//     justify-content: space-between;
//     margin-left: 20px;

//     // div {
//     //   margin-right: 15px;
//     // }
//     &:hover {
//       cursor: pointer;
//     }
//   }
//   .profile-item {
//     display: inline-block;
//     padding: 3px;
//     border: 2px solid $colour-secondary;
//     border-radius: 5px;
//     min-width: 160px;
//     min-height: 24px;
//   }
//   .profile__timespris {
//     input,
//     .profile-item {
//       width: 70px;
//       min-width: unset;
//       &:after {
//         content: "kr";
//       }
//     }
//   }
//   .dropdown-list {
//     padding: 0;
//     margin-left: 20px;
//     margin-top: 2px;
//     li {
//       display: flex;

//       list-style-type: none;
//       .kommune {
//         width: 130px;
//         text-align: left;
//       }
//       button {
//         background-color: unset;
//         display: inline-block;
//         margin: 0;
//         align-self: flex-end;
//         &:hover {
//           background-color: unset;
//           svg rect {
//             fill: green;
//           }
//         }
//       }
//     }
//   }
//   .cleaner-profile-area {
//     display: inline-block;
//     margin-left: 20px;
//     .cleaner-profile-area__label {
//       display: inline-block;
//     }
//     &:first-of-type {
//       margin-left: 0;
//     }
//   }
//   form {
//     margin: 5px 0;
//     width: 100%;
//     max-width: unset;
//     .feedback {
//       flex-basis: 100%;
//       text-align: left;
//       margin-left: 180px;
//     }
//     input,
//     textarea {
//       border: 3px solid $colour-secondary;
//       border-radius: 5px;
//       margin: 0 0 8px 0;
//       //   min-width: 250px;
//     }

//     button {
//       // margin-left: 130px;
//       padding: 3px 6px;
//       background-color: $colour-secondary;
//       &:hover {
//         background-color: lighten($color: $colour-secondary, $amount: 10%);
//       }
//     }
//     .close-button {
//       margin-left: 20px;
//       width: 2rem;
//       height: 2rem;
//       border-radius: 50%;
//       color: $neutral-color;
//       background-color: #1a1919;
//       &:hover {
//         background-color: darken($color: rgb(220, 3, 3), $amount: 10%);
//       }
//     }
//   }
// }

// .infoBesidePicture {
//   //  width: 400px;
//   width: unset;
// }

// .payoutsCard {
//   width: 348px;
//   padding: 20px;

// }

// .customer {
//   width: 400px;
// }
.profilephoto {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexContainerAdmin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
  flex-wrap: wrap;
  .flexCard {
    width: 300px;
    min-height: 150px;
    justify-self: center;
    border: 3px solid $colour-primary;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
  .flexCardList {
    width: 300px;
    min-height: 150px;
    justify-self: center;
    border: 3px solid $colour-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
}

.lengthContainer {
  text-align: center;
}

.backButton {
  margin: 2vw;
}
.profile-container {
  text-align: center;
}
.widthProfile,
.infoBesidePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    border: 2px solid rgba(86, 100, 117, 0.719);
    border-radius: 5px;
  }
  // text-align: center;
  // height: 100%;
}


.section1 {
  // background-color: rgba(255, 0, 0, 0.097);
  // width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  padding: 0px 30%;
  // justify-content: space-evenly;

  .imageSection {
    width: 100%;
    // background-color: rgba($colour-secondary, 0.005);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profilephoto {
    z-index: 20;
 }
  .imageBlob {
    width: 200px;
    height: 200px;
    position: relative;
    background-color: rgba($colour-secondary, 0.1);
    top: -150px;
    right: -30px;
    border-radius: 10px;
    z-index: -4;
  }
  
  .profileSection {
    width: 100%;
    // background-color: rgba($colour-secondary, 0.1);
    
    display: flex;
    flex-direction: column;
    label {
      text-align: left;
    }

    input {
      border: 2px solid rgba(86, 100, 117, 0.719);
      border-radius: 5px;
    }
  }
div.profileSection:first-child {
    margin-top: 20px;
  }


}
.section2, .section3, .section4 {
  // background-color: rgba(0, 0, 255, 0.178);
  // width: 100%;
  min-height: 500px;
  padding: 0px 30%;
  display: flex;
  flex-direction: row;

  .formSection1 {
    width: 100%;
    // background-color: rgba($colour-secondary, 0.1);

    input, textarea {
      border: 2px solid rgba(86, 100, 117, 0.719);
      border-radius: 5px;
      
    }

    textarea {
      margin-top: 5px;
    }

  }


  .formSection2 {
    width: 100%;
    // background-color: rgba($colour-secondary, 0.1);
    display: flex;
    flex-direction: column;

    input {
      border: 2px solid rgba(86, 100, 117, 0.719);
      border-radius: 5px;
    }
  }

  .deleteArea {
    background-color: white;
    border-radius: 12px;
    padding: 2px 7px;
    font-size: 10px;
    margin-left: 5px;
    font-weight: 600;
  }

  .deleteArea:hover {
    background-color: red;
    color: white
  }

  .dropdown-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0px 10px;
    }
  }

  .add {
    margin-left: 5px;
  }
  

  .placesContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0px 10px;
  }
  .cleaner-profile-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
   


    }
}
.section3, .section4 {
  min-height: 100px;
}
.orgNumber {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.orgNumber div:first-child {
  padding-right: 20px;
}

form textarea {
  width: auto;
  max-width: 300px;
  height: 80px;
  max-height: 160px;
}
form label {
  display: block;
}

.imageBlob {
  width: 200px;
  height: 200px;
  position: relative;
  background-color: $colour-secondary;
  top: -180px;
  right: -20px;
  border-radius: 10px;
  z-index: -1;
}



// .counties {
//   display: inline-block;
// }

// .profile__picture .clickable-detail {
//   // display: inline;
//   .image-container {
//     display: inline;
//     min-height: 100px;
//     // background-color: $colour-primary;
//     // border-radius: 180px;
//     text-align: center;
//   }
// }

// .profile-container {
//   width: 90%;
//   margin: 20px auto 20px auto;
//   max-width: 800px;
//   p {
//     margin-bottom: 2px;
//   }
//   .profile-notification {
//     color: darkred;
//   }
//   .public-details {
//     border-bottom: 1px solid black;
//     // border-top: 1px solid black;
//     padding: 10px 0;
//     margin-bottom: 30px;
//   }
// }

// .profile__beskrivelse {
//   .profile-item {
//     display: inline-block;
//     // margin-left: 130px;
//   }
//   form {
//     textarea {
//       flex: 1;
//       vertical-align: top;
//     }
//   }
// }

// .flex-break {
//   flex-basis: auto;
//   // flex-basis: 100%;
// }

.profile-container__detail {
  margin-top: 50px;
}
.profile-container__more__detail {
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-evenly;
  gap: 10px;

  form {
    display: inline-block;
  }

  div form {
    padding: 0px 60px;
  }
}

.location {
  display: flex;
  flex-direction: row;
}

// display: flex;
//   min-height: 30px;
//   // max-width: 500px;
//   margin: 10px auto;
//   span,
//   label {
//     display: inline-block;
//     // width: 120px;
//     margin-left: 20px;
//     // text-align: right;
//     vertical-align: top;
//   }
//   form {
//     display: none;
//     height: 0;
//     transition: all linear 0.5s;
//   }
//   .area-form {
//     // margin-top: 10px;
//     display: flex;
//     height: unset;
//     justify-content: space-between;
//     // width: 1100px;
//     flex-direction: column;
//     // margin-left: 0px;

//     .search-dropdown {
//       display: inline-block;
//     }
//   }
// }

// .profile-container__detail.show-form {
//   .clickable-detail {
//     display: none;
//   }
//   form {
//     height: unset;
//     display: flex;
//     // flex-wrap: wrap;
//     margin-bottom: 20px;
//   }
//   .clickable-picture {
//     width: 300px;
//   }
// }

// .profile-container__detail.profile__picture.show-form {
//   display: inline;
//   .clickable-detail {
//     display: unset;
//   }
// }

// .overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.408);
// }

// .deleteModal {
//   position: absolute;
//   top: 34%;
//   left: 35%;
//   background-color: $neutral-color;
//   border: 2px solid $colour-primary;
//   border-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   min-height: 200px;
//   width: 500px;
//   align-items: center;
//   justify-content: space-evenly;

//   button {
//     margin: 20px 4px;
//   }

//   p {
//     padding: 20px;
//   }
// }

.deleteAccount {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  // margin: 50px 80px 0px 0px;
  margin: 50px 0px;
  text-decoration: underline red;
}

// @media (min-width: 1024px) {
  .profile__allergy {
    h4 {
      text-align: center;
    }
    .allergyIcon {
      width: 50px;
      padding: 5px;
    }
  }
//   .profile-container__detail.show-form {
//     form {
//       margin: 0;
//       button {
//         margin-left: 20px;
//         height: 35px;
//       }
//       .under-button {
//         margin-left: 30px;
//       }
//     }
//   }
//   .profile-page {
//     .profile-item,
//     input {
//       min-width: 310px;
//       box-sizing: content-box;
//     }
//     .profile__timespris {
//       input,
//       .profile-item {
//         width: 70px;
//         min-width: unset;
//       }
//     }

//       button {
//         color: rgb(220, 3, 3);
//         width: 25px;
//         height: 30px;
//         padding: 0;
//         margin: 5px;
//       }
//     }

//     .profile__beskrivelse {
//       .profile-item {
//         // margin-left: 180px;
//         min-height: 100px;
//         display: inline-block;
//       }
//     }
  // }

.profile-container__detail {
  max-width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //     span,
  //     label {
  //       // width: 170px;
  //       padding-right: 20px;
  //     }
}

.county-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.county-detail div:first-child {
  padding-right: 50px;
  // text-align: center;
}

//   .placesContainer {
//     // margin: 50px 0px 20px;
//     display: fleX;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 800px;
//     gap: 10px;
//     justify-content: center;
//   }

//   .flexColumn {
//     display: flex;
//     flex-direction: column;
//   }

//   .spaceBetween {
//     justify-content: space-between;
//   }

//   .hide {
//     display: none;
//   }

//   .arrowDown {
//     display: inline-block;
//     transform: rotate(180deg);
//   }

//   .flexEnd {
//     justify-content: flex-end;
//   }

//   .alignItemsCenter {
//     align-items: center;
//   }

  .flexRow {
    display: flex;
    flex-direction: row;
    gap: 10px;

//     .profile-item {
//       min-width: 150px;
//       margin: 0px 0px 0px 20px;
//     }

//     .profile-container__detail {
//       margin: 10px 0px;
//     }
  }

//   .wrap {
//     flex-wrap: wrap;
//   }

//   .borderContainer {
//     border: 3px solid $colour-primary;
//     border-radius: 8px;
//   }


#nyrenholder__form {
  max-width: 20%;

  .button-container {
    margin-bottom: 20px;
  }
}

#formImage, #formImage2 {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  bottom: 130px;
  // width: 50%;
  // color: blue;
}


#formImage2 {
  bottom: 50px;

  button {
    width: 300px;
  }
}
//   #image {
//     width: 15px;
//   }
// }

// @media (max-width: 600px) {
//   .flexContainer {
//     display: flex;
//     flex-direction: column;

//     .infoBesidePicture {
//       display: flex;
//       flex-direction: column;
//       justify-content: start;
//     }
//   }

//   .deleteModal {
//     top: 20%;
//     left: 0%;
//     width: 90%;

//     button {
//       margin: 0px 2px;
//     }
//   }
// }
