.bestilling-modal {
  display: none;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.7);
  .bestilling-modal__content {
    background-color: $neutral-color;
    padding: 10px;
    width: 40%;
    min-width: 400px;
    max-width: 800px;
    margin: 10% auto 0 auto;
    position: absolute;
    left: calc(100vw/3);
    bottom: 20%;
    form {
      text-align: center;
      button {
        margin: 20px;
        background-color: $colour-secondary;
      }
    }
    .gjentagende {
      text-align: left;
      margin: 0 0 20px 0;
      select {
        background-color: $colour-primary;
        border: 1px solid $colour-secondary;
        border-radius: 5px;
        padding: 5px;
      }
    }
    .bestille-desc, .gjentagende label {
      display: inline-block;
      width: 150px;
    }
    .vasktype {
      text-transform: capitalize;
    }
    #payment-confirmation {
      display: none;
    }
  }
  
  
  .close-button {
    text-transform: lowercase;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.bestilling-modal.open {
  display: block;
}

.stripeElement {
  width: 100%;
}

.payment-confirmation {
  text-align: center;
  margin: 20px auto;
}