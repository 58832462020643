.footer {
  padding: 20px;
  background-color: $colour-primary;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.footer__decoration-one,
.footer__decoration-two {
  position: absolute;
  width: 10px;
  height: 70px;
  top: -35px;
  right: 30px;
  background-color: $colour-secondary;
}

.footer__decoration-two {
  right: 60px;
}

.footer__title {
  font-family: $font-title;
  font-size: $font-size-md;
}

.footer__links-container {
  a {
    display: block;
    margin: 15px 10px;
  }
}

@media (min-width: 600px) {
  .footer__links-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    a {
      margin: 10px 10px 10px 0;
    }
  }
}

@media (min-width: 1024px) {
  .footer__title {
    max-width: 1000px;
    margin: 0 auto;
    font-size: $font-size-lg;
  }
  .footer__links-container {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1000px;
    margin: 0 auto;
  }
}
