.times {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 400px;
  

  label {
    min-width: 80px;
  }

  #feedbackError {
    display: none;
    
  }
}

.time-picker {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#renholder-page {
  .image-container {
    width: 80%;
    margin: 0 auto;
    max-width: 300px;

    img {
      width: 100%;
      height: 200px;
      width: 200px;
      border-radius: 20px;
      object-fit: cover;
    }
  }
  h2,
  h3 {
    padding: 0 10%;
  }

  .info {
    padding: 0 10%;
    text-align: center;
  }
  h3 {
    text-align: left;
  }
  hr {
    width: 80%;
  }
  .button-container {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    button {
      background-color: $neutral-color;
      border: 3px solid $colour-secondary;
      &:hover {
        border-color: darken($color: $colour-secondary, $amount: 10%);
      }
    }
  }
  .text-container {
    width: 80%;
    margin: 0 auto;
    max-width: 300px;
    .area-container {
      // text-align: center;
    }
    div {
      margin-bottom: 20px;
    }
    svg {
      display: inline-block;
      // width: 100%;
      margin: 10px auto;
    }
    g path {
      fill: black;
    }
  }

  .chat-modal-wrapper {
    display: none;

    &.visible {
      display: block;
      // position: absolute;
      // top: 0;
    }
  }

  .chat-modal {
    // display: none;
    position: fixed;
    z-index: 15;
    width: 90%;
    min-height: 200px;
    padding-top: 40px;
    border: 2px solid rgb(213, 213, 213);
    border-radius: 5px;
    top: 20vh;
    left: 5%;
    background-color: $colour-primary;

    .chat-history {
      height: 50vh;
      background-color: rgb(252, 252, 252);
      border: none;
      font-size: $font-size-sm;
    }

    form {
      background-color: rgb(252, 252, 252);
      border-radius: 0 0 5px 5px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      max-width: 100%;
      margin: -1px;
      border: 1px solid rgb(213, 213, 213);


      input {
        background-color: rgb(252, 252, 252);
        font-size: $font-size-sm;
        padding-left: 10px;
        width: 100%;
        margin: 0;
        border: none;
        outline: none;
      }

      button {
        border: none;
        background-color: gray;
        color: $neutral-color;
        border-radius: 20px;
        text-transform: capitalize;
        font-size: $font-size-sm;
        padding: 0 10px;
        margin-right: 5px;
        height: 30px;
        transition: all 0.3s;

        &:hover {
          filter: brightness(150%);
        }
      }

  
    }



    .close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 15px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: rgb(203, 203, 203);
        border-radius: 2px;
      }

      &::after {
        content: "";
        background-color: #000000;
        position: absolute;
        width: 20px;
        height: 2px;
        rotate: 45deg;
        translate: -50%;
        left: 50%;
      }

      &::before {
        content: "";
        background-color: black;
        position: absolute;
        width: 20px;
        height: 2px;
        rotate: -45deg;
        translate: -50%;
        left: 50%;
      }
    }
  }
}

.velg-vask-container {
  width: 80%;
  max-width: 300px;
  margin: 0 auto 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.vask-type {
  width: 140px;
  padding: 8px 0;
  &.selected {
    background-color: $colour-secondary;
  }
}
.renholder-bestilling {
  max-width: 1000px;
  margin: 20px auto;
}

.messages-container {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
}

.time-picker {
  text-align: center;
  input {
    visibility: hidden;
  }
  label {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid $colour-secondary;
    border-radius: 5px;
    margin: 5px;
    &.on {
      background-color: $colour-primary;
    }
    &:hover {
      cursor: pointer;
    }
  }


}

@media (min-width: 1024px) {
  #renholder-page {
    .renholder-details {
      display: flex;
      justify-content: center;
    }
    .image-container {
      width: 300px;
      margin-right: 10px;
    }
    .text-container {
      margin-left: 10px;
    }
    .velg-vask-container {
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 650px;
    }
    .vask-type {
      width: 200px;
    }
    .chat-modal {
      width: 40vw;
      left: 30vw;
    }
  }
}
