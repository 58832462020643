.filter-container {
  text-align: center;
  margin: 30px auto 50px auto;
}

.finnrenholder-page {
  h2 {
    max-width: 280px;
    text-align: center;
    margin: 20px auto;
  }
  .filter-container {
    position: relative;
    width: 80%;
    max-width: 400px;
    label {
      position: absolute;
      z-index: -1;
      top: 4px;
      left: 8px;
      width: 25px;
      height: 25px;
    }
    input {
      border: 2px solid $colour-secondary;
      border-radius: 5px;
      padding: 5px 0px 5px 35px;
      margin-top: 0;
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
    }
  }
  
  .featured-container {
    width: 80%;
    margin-bottom: 80px;
    .featured__rating svg {
      width: 100px;
      height: 20px;
    }
  }
}


@media (min-width: 450px) {
  .finnrenholder-page{
    h2 {
      margin: 40px auto;
      max-width: unset;
    }
  }
}

@media (min-width: 1024px) {
  .finnrenholder-page {
    .results-container {
      max-width: 1500px;
    }
    .filter-container {
      
      label {
        width: 30px;
        height: 30px;
        left: 20px;
      }
      input {
        padding-left: 60px;
      }
    }
  }
  // .results-container {
  //   max-width: 2000px;
  // }
  
}