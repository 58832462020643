.content {
  margin: 0px 200px;
}

.about-us-section, .how-it-works-section, .guidelines-section {
  max-width: 50%;
  margin: 0px auto;
}

.how-it-works-section {
  h2, h1 {
    text-align: left;
  }
}

#contact__form {
  display: flex;
  flex-direction: column;
  max-width: 20%;
  margin: 0px auto;
  
  .form-item #textareaField {
    width: 100%;
    max-width: 100%;
    height: 500px;
  }

  button {
    margin-top: 20px;
  }
}

@media  (max-width: 600px) {
  .content {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }


}