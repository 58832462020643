.nybruker-page {
  h2 {
    max-width: 350px;
    margin: 30px auto;
  }
  img {
    display: none;
  }
  .link-container {
    width: 200px;
    margin: 20px auto;
    border: 3px solid $colour-secondary;
    border-radius: 10px;
    text-align: center;
    a {
      padding: 20px 0;
      width: 100%;
      display: inline-block;
    }
    &:hover {
      cursor: pointer;
      border-color: darken($color: $colour-secondary, $amount: 20%);
    }
  }
}

@media (min-width: 600px) {
  .nybruker-page {
    .selection-container {
      width: 80%;
      max-width: 600px;
      margin: 30px auto;
      display: flex;
    }
    .selection__links-container {
      flex: 3;
    }
    .selection__img-container {
      flex: 2;
    }
    img {
      display: unset;
      width: 100%;
      border-radius: 10px;
    }
  }
}

@media (min-width: 1024px) {
  .nybruker-page {
    h2 {
      max-width: 500px;
      margin: 30px auto;
    }
  }
}